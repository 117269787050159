<template>
  <b-card title="Lista de Rondas" id="cardo">
    <div v-if="toExport.length > 0" id="export-button">
      <json-excel :data="toExport">
        <feather-icon
          icon="SaveIcon"
          size="40"
          class="ml-2 text-success pointer"
        />
      </json-excel>
    </div>
    <b-table
      :items="rounds"
      :fields="fields"
      striped
      responsive
      class="data-table"
    >
      <template #cell(show_details)="row">
        <b-form-checkbox
          v-model="row.detailsShowing"
          plain
          class="vs-checkbox-con"
          @change="row.toggleDetails"
        >

        </b-form-checkbox>
      </template>

      <template #cell(actions)="data">
        <b-link :href="'/round-single/'+ data.item.id">
          <feather-icon
            icon="EyeIcon"
            class="mr-50 text-primary"
          />
        </b-link>
        <b-link :href="'/masters/client-single/'+ enc(data.item.id)" v-if="checkContains('master-details') == true">
          <feather-icon
            icon="EyeIcon"
            class="mr-50 text-primary"
          />
        </b-link>
        <feather-icon
          v-if="checkContains('remove-relation') == true"
          icon="TrashIcon"
          class="mr-50 text-warning pointer"
          @click="removeRelation(relationId(data.item))"
        />
        <feather-icon
          v-if="checkContains('add-relation') == true"
          icon="PlusIcon"
          class="ml-2 text-success pointer"
          @click="addRelation(data.item)"
        />
      </template>
      <!-- <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{status[0][data.value]}}
        </b-badge>
      </template> -->
    </b-table>
  </b-card>
</template>
<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BBadge,BCardText, BLink
} from 'bootstrap-vue'
import JsonExcel from "vue-json-excel";

import gql from 'graphql-tag'
import {_} from 'vue-underscore';

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BCardText,
    BBadge,
    BLink,
    underscore: _,
    JsonExcel,
  },
  props:['searchedRounds', 'contextActions', 'addInfo', 'customFields', 'pageInfo'],
  data() {/*bets_aggregate(where:{status: {_eq:"CONFIRMED"}}){
        aggregate{
          sum{
            amount
          }
        }
      }  */
    return {
      fields: ['show_details', 'id','code',
      { key: "bets_aggregate.aggregate.sum.amount", label: "apuestas"},
      { key: "winners_aggregate.aggregate.sum.win_value", label: "premios"},
      'result_ball','status','created_at','updated_at',
      { key: 'actions', label: 'Ações' }
],
      rounds:null,
      toExport:[]
    }
  },
  apollo:{

  },
  watch:{
    searchedRounds: function(){
      this.rounds = this.searchedRounds
      _.each(this.searchedRounds, this.formatSave);
    },

  },
  methods:{
    formatSave(e){
      let toSave = {
        "id" : e.id,
        "code": e.code,
        "status": e.status,
      }
      this.toExport.push(toSave);
    },
    checkContains(index){
     return _.contains(this.contextActions,index); 
    },
    dateFormatter(value){
      return this.$datter.convert(value);
    },
    enc(id){
      return btoa(id);
    }
  },
  created(){

  }
}
</script>

<style>
  .floater{
    /* position: absolute; */
    z-index: 99;
  }
  .data-table tbody{
    padding-bottom: 30px;
  }
  #export-button{
    position: absolute;
    top: 10px;
    right: 10px;
  }
  #cardo{
    position:relative
  }

</style>