<template>
  <div>
    <div v-if="!$apollo.loading">
      <b-card title="Busque um Ronda 🔍">

        <b-row>
          <!--<b-col
            cols="6"
            lg="6">
        <b-card-text>No campo abaixo busque por: Email, Nome, Nickname</b-card-text>
        <b-form-input
          icon-no-border
          @keyup.enter.native="searchRound()"
          placeholder="Digite e pressione ENTER"
          v-model="searchQuery"
          class="input-rounded-full"
          icon="icon-search"
          icon-pack="feather"
          :state="docState"
          aria-describedby="input-live-help input-live-feedback"
          id="live-query"
        />
        <b-form-invalid-feedback id="input-live-feedback">
          Pelo menos 3 letras
        </b-form-invalid-feedback>

      </b-col>-->
          <b-col
            cols="6"
            lg="3"
          >
            <b-form-group>

              <label for="group-type">Estado</label>
              <b-form-select
                v-model="filterStatus"
                size="lg"
                @change="searchRound()"
                @click="searchRound()"
              >

                <b-form-select-option
                  v-for="item in statuses"
                  :key="item.id"
                  :data="item"
                  :value="item.id"
                  @click="searchRound()"
                >
                  {{ item.name }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>

          </b-col></b-row>
        <b-row><b-col>
          <rounds-list
            :searched-rounds="queryResult"
            :context-actions="['details']"
          />

        </b-col></b-row>      </b-card>

    </div>
    <div v-else>
      <logo-loader-animation />
    </div>
  </div>

</template>

<script>
import {
  BCard, BCardText, BLink, BFormInput, BFormInvalidFeedback, BFormSelectOption, BFormSelect, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import RoundsList from '@/components/roulette/RoundsList.vue'
import gql from 'graphql-tag'
import { _ } from 'vue-underscore'
import LogoLoaderAnimation from '@/components/misc/LogoLoaderAnimation.vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BFormInput,
    RoundsList,
    BFormInvalidFeedback,
    underscore: _,
    LogoLoaderAnimation,
    BRow,
    BCol,
    BFormSelectOption,
    BFormSelect,
    BFormGroup,

  },
  data() {
    return {
      searchQuery: '',
      currentPage: 1,
      searchResults: [],
      queryResult: null,
      pageToSearch: 1,
      pageInfo: {},
      filterStatus: null,
      statuses: [{ id: 'ENDED', name: 'Finalizado' },
        { id: 'WAITING', name: 'Esperando' },
        { id: 'OPEN', name: 'Abierta' },
        { id: 'STARTING', name: 'Por Comenzar' },
        { id: 'STARTED', name: 'Comenzado' },

      ],
    }
  },
  computed: {
    docState() {
      return this.searchQuery.length > 2
    },
  },
  created() {
    this.searchRound()
  },
  methods: {
    searchRound() {
      // if(this.searchQuery == '' || !document.getElementById('live-query').getAttribute("aria-invalid")){
      let direction = 'asc'

      if (this.filterStatus == 'ENDED') {
        direction = 'desc'
      }

      let statuses = ['OPEN', 'STARTED', 'STARTING', 'WAITING', 'RESULTED']
      if (this.filterStatus != null) statuses = [this.filterStatus]

      this.$apollo.query({ /**/
        query: gql`
           
     query rounds($offset: Int, $statuses: [round_status!]!, $direction: order_by) {
    rounds(order_by: {id: $direction}, limit: 200, offset: $offset, where: {status: {_in: $statuses}} ) {
      id
      code
      configurations {
        key
        value
      }
      created_at
      updated_at
      result_ball
      status
      updated_at
     
      winners_aggregate{
        aggregate{
          sum{
            win_value
          }
        }
      }
      bets_aggregate(where:{status: {_eq:"CONFIRMED"}}){
        aggregate{
          sum{
            amount
          }
        }
      }      
    }
  }
          `,
        variables: {
          offset: (this.pageToSearch - 1) * 30,
          statuses,
          direction,
        },
        client: 'rouletteClient',
      }).then(data => {
        // Result
        this.queryResult = data.data.rounds
      }).catch(error => {
        console.error(error)
      })
      // }
    },
    paginate(eV) {
      if (this.pageToSearch == 1) {
        this.pageToSearch = 2
      } else {
        this.pageToSearch += eV
      }
      this.searchRound()
    },
  },
}
</script>

<style>

</style>
